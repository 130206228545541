<template>
	<div id="mainBlockSelector" class="contentBlock" v-if="(userCanPdl && userCanBase) && !!getSelectedDeal">
		<div class="title">
			<h3>Оформить заём</h3>
		</div>
		<div class="body">
			<div class="selectBlock">
				<formSelect :refs="'CALC_SUMM'" :type="'deal'"></formSelect>
				<div class="slWrap">
					<range-slider class="slider" :min="0" :max="getActiveDistinctAmounts.length - 1" :step="1"
						v-model="rangeValueSumm">
					</range-slider>
					<div class="fromTo">
						<p class="pa">от {{ getActiveMinDeal | money }} ₽</p>
						<p class="pb">до {{ getActiveMaxDeal | money }} ₽</p>
					</div>
				</div>
				<div class="bottomText">
					Возвращаете:
					<strong>{{ getSelectedDeal.OutPayments }}</strong>
				</div>
			</div>
			<div class="selectBlock">
				<formSelect :refs="'CALC_WEEK'" :type="'deal'"></formSelect>
				<div class="slWrap">
					<range-slider class="slider" :min="0" :max="getActiveDistinctPeriods.length - 1" :step="1"
						v-model="rangeValuePeriod">
					</range-slider>
					<div class="flex">
						<p v-for="(item, index) in getActiveDistinctPeriods" v-bind:key="index" class="descTxt">
							{{ item.name }}
						</p>
					</div>
				</div>
				<div class="bottomText">
					До (включительно):
					<strong>{{ getSelectedDeal.payDay }}</strong>
				</div>
			</div>
			<div class="getMoney">
				<button @click="toRepeatloan()" v-if="showButton">
					Получить деньги
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import RangeSlider from 'vue-range-slider';
import 'vue-range-slider/dist/vue-range-slider.css';
import tracing from '@/common/tracing';

export default {
	name: 'uiProductAmountSelector',

	props: {
		showButton: {
			type: Boolean,
			default: true
		}
	},

	components: {
		RangeSlider,
		formSelect: () => import('./components/formSelect')
	},

	computed: {
		...mapGetters('repeatLoan', [
			'getSelectedDeal',
			'getActiveDistinctAmounts',
			'getActiveMinDeal',
			'getActiveMaxDeal',
			'getActiveDistinctPeriods',
			'getSession',
			'userCanBase',
			'userCanPdl'
		]),
		...mapState({
			valueVuex: (state) => state.repeatLoan.sendData
		}),
		rangeValueSumm: {
			get() {
				return this.getActiveDistinctAmounts.findIndex(
					(x) => x.value === this.valueVuex.CALC_SUMM
				);
			},
			set(value) {
				this.$store.dispatch('repeatLoan/saveSendData', {
					target: 'CALC_SUMM',
					value: this.getActiveDistinctAmounts[value].value
				});
				this.changeCalc();
			}
		},
		rangeValuePeriod: {
			get() {
				return this.getActiveDistinctPeriods.findIndex(
					(x) => x.value === this.valueVuex.CALC_WEEK
				);
			},
			set(value) {
				this.$store.dispatch('repeatLoan/saveSendData', {
					target: 'CALC_WEEK',
					value: this.getActiveDistinctPeriods[value].value
				});
				this.changeCalc();
			}
		}
	},
	async mounted() {
		await this.$store.dispatch('repeatLoan/init');
		this.$store.dispatch('repeatLoan/setDefaultCalcParams');
	},
	methods: {
		changeCalc() {
			tracing.traceInput({
				action: 165,
				refs: 'CALC_SUMM',
				value: this.valueVuex.CALC_SUMM
			});
			tracing.traceInput({
				action: 165,
				refs: 'CALC_WEEK',
				value: this.valueVuex.CALC_WEEK
			});
			tracing.traceInput({
				action: 165,
				refs: 'pdlGetSelectedDeal',
				value: this.getSelectedDeal
			});
		},
		async toRepeatloan() {
			await tracing.sendTrace(
				this.getSession.session_id,
				this.getSession.token
			);
			await this.$store.dispatch('repeatLoan/changeCurrentScreen', 'fio');
			if (this.$route.path !== '/repeatLoan') {
				this.$router.push('/repeatLoan');
			}
		}
	}
};
</script>

<style>
#mainBlockSelector select{ margin-bottom: 4.71vw; font-size: 3.47vw; border: 1px solid rgba(67, 172, 52, 0.5); border-radius: 10px ; color: #959595; display: block; padding: 2.83vw 4.6vw; width: calc(100% + 1vw); }
#mainBlockSelector .inputBoxWrap label { padding: 0 2.67vw; top: 1px; color: #869A95; transition: 0.3s ease-out; }
#mainBlockSelector select:focus ~ label { top: -2vw; }

@media (min-width: 760px) {
	#mainBlockSelector select { border-radius: 10px; font-size: 2vw; margin-bottom: 1.29vw; color: #959595; line-height: 4vw; padding: 1.6vw 2.6vw; width: calc(100% - 0.4vw);}
	#mainBlockSelector .inputBoxWrap label { font-size: 2vw; left: 1.7vw; padding: 0 1.5vw; }
  	#mainBlockSelector .inputBoxWrap select:focus ~ label { top:-0.5vw }
}

@media (min-width: 1240px) {
	#mainBlockSelector .inputBoxWrap label { left: 23px; font-size: 10px;  padding: 0 10px; }
  	#mainBlockSelector .inputBoxWrap select { font-size: 14px; margin-bottom: 16px ; line-height: normal; padding: 10px 28px; width: 100%;}
  	#mainBlockSelector select:focus ~ label {top:-6px}
}
</style>

<style scoped>
.body { margin-bottom: 12.65vw; display: flex; flex-direction: column; align-items: center; }
.selectBlock { width: calc(100% - 1vw); }
.slWrap > p { text-align: center; text-transform: lowercase }
.slWrap > p > b { text-align: center; padding: 0 1.33vw }
.slider { width: 100%; box-sizing: border-box; padding: 0; margin-bottom: 2.94vw  }
.fromTo { display: flex; justify-content: space-between; }
.fromTo > p { margin-bottom: 4.41vw; font-size: 2.94vw }
.flex { display: flex; justify-content: space-between }
.flex > p { font-size: 2.94vw }
/deep/ .slider > .range-slider-inner > .range-slider-hidden { display: none; }
/deep/ .slider > .range-slider-inner > .range-slider-rail { background-color: #43AC34; height: 1.5px;}
/deep/ .slider > .range-slider-inner > .range-slider-fill { background-color: #0C5A44; }
/deep/ .slider > .range-slider-inner > .range-slider-knob { border: 0.27vw solid #0D5740; background-color: #0D5740; box-shadow: inset 0 0 0 0.13rem white; width: 4.27vw; height: 4.27vw; }
.disable{pointer-events: none}
#mainBlockSelector { display: flex; flex-direction: column; align-items: left; }
.getMoney { padding: 5vw; }
.getMoney > button{ padding: 2vw; font-size: 3.5vw;}
.bottomText { display: none; }
.inputBoxWrap select{ width: calc(100% - 11.76vw); }

@media (min-width: 760px) {
  /*.descTxt {font-size: .52rem;}*/
  .body { flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: center; }
  .body > .selectBlock { padding: 1vw; width: calc(50% - 2vw);}
  /deep/ .slider { margin-bottom: 0.97vw }
  /deep/ .slider > .range-slider-inner > .range-slider-knob { border: 0.17vw solid #0D5740; background-color: #0D5740; box-shadow: inset 0 0 0 0.13rem white; width: 1.37vw; height: 1.37vw; }
  /deep/ .slider > .range-slider-inner > .range-slider-rail { height: 2px }
  /deep/ .slider > .range-slider-inner > .range-slider-fill { height: 2px }
  .slWrap > p { }
  .fromTo > p { font-size: 1.5vw; margin-bottom: 1.61vw }
  .flex > p { font-size: 1.5vw }
  .fromTo { padding: 0 }
   #mainBlockSelector > h3 { padding-left: 3vw; }
	.getMoney { padding: 2vw; min-width: 20vw; margin-bottom: 1.61vw; }
  .getMoney > button{ padding: 1vw; font-size: 2.5vw }

  }
@media (min-width: 1240px) {
  .descTxt { }
  .body { margin-bottom: 55px; align-items: baseline; flex-wrap: nowrap;}
  .body > .selectBlock{ padding: 10px;width: 100%; }
  .body > div > h3 { margin-bottom: 32px; padding: 0; font-size: 18px }
  .body > div > h2 {  }
  /deep/ .slider { margin-bottom: 5px }
  /deep/ .slider > .range-slider-inner > .range-slider-knob { border: 2px solid #0D5740; box-shadow: inset 0 0 0 2px white; width: 17px; height: 17px; }
  .slWrap > p { opacity: 0.4 }
  .fromTo > p { opacity: 0.4; font-size: 12px; margin-bottom: 1vw; }
  .flex > p { opacity: 0.4; font-size: 12px; margin-bottom: 1vw;  }
	.getMoney { padding: 20px; min-width: 10vw; margin-bottom: 1.61vw; }
  .getMoney > button{ padding: 10px; font-size: 14px }
	.bottomText { display: block; }
	#mainBlockSelector select { padding: 12px 33px; font-size: 14px; margin-bottom: 16px ;width: calc(100% - 40px); line-height: normal; padding: 10px 28px; width: 100%;}

}


</style>
